import { Area } from "../models/filter-cloth";
import { FilterPress } from "../models/filter-press";

export const smartTrim = (string: string, maxLength: number = 12) => {
  if (!string) return string;
  if (maxLength < 1) return string;
  if (string.length <= maxLength) return string;
  if (maxLength === 1) return string.substring(0, 1) + "…";

  var midpoint = Math.ceil(string.length / 2);
  var toremove = string.length - maxLength;
  var lstrip = Math.ceil(toremove / 2);
  var rstrip = toremove - lstrip;
  return (
    string.substring(0, midpoint - lstrip) +
    "…" +
    string.substring(midpoint + rstrip)
  );
};

export const areas: Area[] = [
  "A1",
  "B1",
  "C1",
  "A2",
  "B2",
  "C2",
  "A3",
  "B3",
  "C3",
];

export const parseHighlightedAreas = (highlightedAreas: string): Area[] =>
  highlightedAreas
    .split(",")
    .filter((v) => v)
    .sort() as Area[];

export const findClothMode = (
  clothModeConfig: FilterPress["clothModeConfig"],
  plateIndex: number
) => {
  if (!clothModeConfig) {
    return null;
  }

  return clothModeConfig.find((entry) => entry.plateIndex === plateIndex)
    ?.clothMode;
};
