import { ApolloProvider } from "@apollo/client";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import React from "react";
import ReactDOM from "react-dom";
import client from "./apollo-client";
import App from "./App";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// Ionic PWA elements
defineCustomElements(window);
